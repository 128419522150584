import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPortalPage } from '../actions/blanket';

const CommissionCalculation = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPortalPage('Commission Calculation - Reports'));
    }, [dispatch]);

    return <></>
}

export default CommissionCalculation;