import React, { useState, useEffect } from "react";
import { Card, Image, Row, Col } from "react-bootstrap";
import './styles.scss';
import { Button } from "@themesberg/react-bootstrap";

const ProductDetail = (props) => {
    const { cancelEdit, product } = props;

    const primaryResource = product.resources.find(resource => resource.isPrimary);
    const initialMainResource = primaryResource ? primaryResource : null;

    const [mainResource, setMainResource] = useState(initialMainResource);
    const [selectedResource, setSelectedResource] = useState(initialMainResource);

    const handleThumbnailClick = (resource) => {
        setMainResource(resource);
        setSelectedResource(resource);
    };

    useEffect(() => {
        // Update main resource if product data changes
        setMainResource(initialMainResource);
        setSelectedResource(initialMainResource);
    }, [initialMainResource]);

    return (
        <Card border="light" className="table-wrapper table-responsive shadow-sm no-border-radius">
            <Card.Body className="pt-0">
                <h3 className="mb-3 border-bottom border-light mt-3 pb-md-3 mb-md-3">{product.name}</h3>
                <Row className='align-items-start'>
                    <Col md={6} className="mb-3">
                        <div className="d-xl-flex align-items-center">
                            {mainResource && mainResource.resourceType === "video" ? (
                                <video className="main-view" controls>
                                    <source src={mainResource.resourceUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <Image className="main-view" fluid rounded src={mainResource?.resourceUrl || process.env.REACT_APP_NO_IMAGE} />
                            )}
                        </div>
                    </Col>
                    <Col md={6} className="mb-3">
                        <Card border="light" className="shadow-sm">
                            <Card.Body>
                                <div className="d-flex align-items-center justify-content-between border-bottom border-light pb-3">
                                    <h1 className="mb-1">{product.price.toLocaleString()} Php</h1>
                                </div>
                                <div className="d-flex align-items-center border-bottom border-light pt-3 product-info">
                                    <h5 className="mb-1">Mark Up: </h5>
                                    <p>{product.markUpPrice.toLocaleString()} Php</p>
                                </div>
                                <div className="d-flex align-items-center border-bottom border-light pt-3 product-info">
                                    <h5 className="mb-1">Model: </h5>
                                    <p>{product.model}</p>
                                </div>
                                <div className="d-flex align-items-center border-bottom border-light pt-3 product-info">
                                    <h5 className="mb-1">Color: </h5>
                                    <p>{product.color}</p>
                                </div>
                                <div className="d-flex align-items-center border-bottom border-light pt-3 product-info">
                                    <h5 className="mb-1">Size: </h5>
                                    <p>{product.size}</p>
                                </div>
                                <div className="d-flex align-items-center pt-3">
                                    <div className="pt-2">
                                        <Row className="flex-row">
                                            <Col className="product-description" md={12}>
                                                <h2 className="fs-5">Description</h2>
                                                <p>{product.description}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <ul className="item-gallery d-flex p-0 list-unstyled">
                    {product.resources.map(resource => (
                        <li key={resource._id} className="p-2">
                            {resource.resourceType === "video" ? (
                                <video 
                                    className={`thumbs-view ${selectedResource === resource ? "selected" : ""}`} 
                                    width="100" 
                                    onClick={() => handleThumbnailClick(resource)} 
                                >
                                    <source src={resource.resourceUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <Image 
                                    className={`thumbs-view ${selectedResource === resource ? "selected" : ""}`} 
                                    fluid rounded 
                                    src={resource.thumbnail || resource.resourceUrl} // Use thumbnail if available, otherwise the main image
                                    onClick={() => handleThumbnailClick(resource)}
                                />
                            )}
                        </li>
                    ))}
                </ul>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-end">
                <Button variant="primary" type="button" onClick={cancelEdit}>Hide</Button>
            </Card.Footer>
        </Card>
    );
}

export default ProductDetail;
