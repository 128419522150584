import React from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import './styles.scss';
import SeaRoadBrand from '../assets/img/brand/sea-road-dark.png';
import { formatCurrency } from "../utils/formatter";
import { DEFAULT_PH_LOCALE_DATE_FORMAT } from "../utils/constants";

const BillingPreview = (props) => {
    const {billingDocumentData} = props;

    const billingDescription = billingDocumentData.billingItems.map(item => item.itemDescription).join(' / ');

    // Mask the container number
    const containerNumber = billingDocumentData.generatedBillingData.containerNumber;
    const maskedContainerNumber = `${containerNumber.slice(0, 4)}****${containerNumber.slice(-3)}`;

    // Format the billing date
    const billingDate = new Date(billingDocumentData.generatedBillingData.billingDate);
    const formattedBillingDate = billingDate.toLocaleDateString(DEFAULT_PH_LOCALE_DATE_FORMAT, {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
    });

    // Calculate subtotals
    let subTotalPackaging = 0;
    let subTotalVolume = 0;
    let subTotalWeight = 0;
    let subTotalFreightCharge = 0;
    let subTotalSensitiveCharge = 0;

    billingDocumentData.billingItems.forEach(item => {
        subTotalPackaging += item.numberOfPackage;
        subTotalVolume += item.cbm;
        subTotalWeight += item.grossWeight;
        subTotalFreightCharge += item.itemFreightCharge;
        subTotalSensitiveCharge += item.itemSensitiveCharge;
    });

    const displayAppliedUnit = (categoryUnit) => {
        if(categoryUnit === "CBM") return "cubic meter";
        return "kilogram"
    }

    const displaySubTotalCBMorKG = (categoryUnit) => {
        if(categoryUnit === "CBM") return subTotalVolume;
        return subTotalWeight
    }

    const displaySubTotalUnit = (categoryUnit) => {
        if(categoryUnit === "CBM") return "cu.m";
        return "kg"
    }
    
    return (
        <Card className="bg-white shadow-sm mb-4" style={{width: '100%'}}>
            <Card.Body>
                <Row>
                    <Col md="12">
                        <div className="preview-container">
                            <div className="preview-header">
                                <Image src={SeaRoadBrand} className="navbar-brand-light" alt="SEA ROAD TRADING INC." />
                                <div className="company-info">
                                    <h2>SEA ROAD TRADING INC.</h2>
                                    <p>Gochan Compound, Warehouse 5B, MJ Cuenco Avenue, Mabolo, Cebu City, Philippines</p>
                                    <p>Telephone No.: (032) 434-6453 | TIN#: 274-454-701-000</p>
                                </div>
                            </div>
                            <div className="border-line-up"></div>
                            <div className="border-line-down"></div>
                        </div>
                    </Col>
                </Row>
                <div className="preview-body clearfix mb-5">
                    <h1>Billing Statement</h1>
                    <div className="left">
                        <p>To: {`${billingDocumentData.clientData.firstName} ${billingDocumentData.clientData.lastName}`}</p>
                        <p>{billingDocumentData.generatedBillingData.clientRefWithFcc}</p>
                        <p>&nbsp;</p>
                        <p>Re: Billing For {billingDescription}</p>
                    </div>
                    <div className="right">
                        <p>Date: {formattedBillingDate}</p>
                        <p>Container No.: {maskedContainerNumber}</p>
                        <p>Invoice No.: {billingDocumentData.generatedBillingData.invoiceNumber}</p>
                        <p>Tracking No.: {billingDocumentData.generatedBillingData.trackingReference}</p>
                    </div>
                </div>
                <p>A. Product Details:</p>
                <div className="preview-responsive">
                    <table className="tbl-document-preview">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Specifications</th>
                                <th>Quantity <span>(PCS)</span></th>
                                <th>Packaging <span>(TOT. CTNs)</span></th>
                                <th>Volume <span>(CBM)</span></th>
                                <th>Weight <span>(KG)</span></th>
                                <th>Unit<span>Cost (PHP/PQ)</span></th>
                                <th>Total Product<span>Cost(RMB)</span></th>
                                <th>Total Product<span>Cost(PHP)</span></th>
                                <th>Sensitive <span>Charge(PHP)</span></th>
                                <th>Inland<span>Cost (PHP)</span></th>
                                <th>Forklift<span>Cost (PHP)</span></th>
                                <th>Freight<span>Charge (PHP)</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {billingDocumentData.billingItems.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.itemDescription}</td>
                                    <td></td>
                                    <td>-</td>
                                    <td>{item.numberOfPackage}</td>
                                    <td>{item.cbm.toFixed(2)}</td>
                                    <td>{item.grossWeight}</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>{formatCurrency(parseFloat(item.itemSensitiveCharge))}</td>
                                    <td>{formatCurrency(parseFloat(item.inlandTransportation))}</td>
                                    <td>{formatCurrency(parseFloat(item.forkliftCharge))}</td>
                                    <td>{formatCurrency(parseFloat(item.itemFreightCharge))}</td>
                                </tr>
                            ))}
                            <tr className="sub-total-row">
                                <td colSpan={3} className="sub-total">SUB-TOTAL</td>
                                <td>{subTotalPackaging}</td>
                                <td>{subTotalVolume.toFixed(2)}</td>
                                <td>{subTotalWeight}</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>{formatCurrency(parseFloat(subTotalSensitiveCharge))}</td>
                                <td>-</td>
                                <td>-</td>
                                <td>{formatCurrency(parseFloat(subTotalFreightCharge))}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-responsive">
                    <table className="table table-freight">
                        <tbody>
                            <tr>
                                <td className="first-content" colSpan={2}>B. Freight Charge: PHP {formatCurrency(parseFloat(billingDocumentData.appliedRate))} per {displayAppliedUnit(billingDocumentData.appliedUnit)}</td>
                                <td className="second-content" colSpan={1}>PHP {formatCurrency(parseFloat(billingDocumentData.appliedRate))}</td>
                                <td className="third-content" colSpan={1}>x</td>
                                <td className="forth-content">{displaySubTotalCBMorKG(billingDocumentData.appliedUnit)}</td>
                                <td className="fifth-content" colSpan={1}>{displaySubTotalUnit(billingDocumentData.appliedUnit)}</td>
                                <td className="sixth-content">=</td>
                                <td className="seventh-content">PHP {formatCurrency(parseFloat(subTotalFreightCharge))}</td>
                                <td className="eigth-content"> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <table className="table table-remarks">
                    <tbody>
                        <tr>
                            <td>Remarks:</td>
                        </tr>
                        <tr>
                            <td>*Exchange Rate (Based on the Loading day): <span className="conversion">USD 1.00 = PHP <span className="figure">{billingDocumentData.containerUSDConversion}</span></span></td>
                        </tr>
                        <tr>
                            <td>*Exchange Rate (Based on the Loading day): <span className="conversion">RMB 1.000 = PHP <span className="figure">{billingDocumentData.containerRMBConversion}</span></span></td>
                        </tr>
                        <tr>
                            <td>* Cargo (per shipment) PhP {formatCurrency(parseFloat(billingDocumentData.appliedRate))} times Per {billingDocumentData.appliedUnit}</td>
                        </tr>
                        <tr>
                            <td>*All Charges should be collected on or before delivery.</td>
                        </tr>
                        <tr>
                            <td>*Cargo delivery: Unloading at Parking Area ONLY.</td>
                        </tr>
                        <tr>
                            <td>*Delivery: Within Cebu City, Mandaue City, Talisay City, Lapu-Lapu City ONLY.</td>
                        </tr>
                        <tr>
                            <td>*Unclaimed and or Unlabelled goods shall be disposed by Sea Road 30 days from date of arrival.</td>
                        </tr>
                        <tr>
                            <td>*Freight Payment: On or before delivery date in the form of Cash, Bank Deposit, or On Date Check on date of delivery.</td>
                        </tr>
                        <tr>
                            <td>*Storage Fees: One Week Free of Charge. Succeeding days storage: Three Hundred Pesos per cubic meter per day (PHP 300/cbm/day) from the date freight Billing Statement is sent to client.</td>
                        </tr>
                        <tr className="remarks-space"><td></td></tr>
                        <tr className="deposit">
                            <td>Please deposit your payment to our BDO account only.</td>
                        </tr>
                        <tr className="account">
                            <td>Account Name: <span>SEA ROAD TRADING INC.</span></td>
                        </tr>
                        <tr className="account">
                            <td>Account Name: <span>0023-1053-7868</span></td>
                        </tr>
                        <tr className="remarks-space"><td></td></tr>
                        <tr className="closing">
                            <td>"This document is not valid for claiming input taxes."</td>
                        </tr>
                    </tbody>
                </table>
            </Card.Body>
        </Card>
    );
}

export default BillingPreview;
