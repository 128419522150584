import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPortalPage } from '../actions/blanket';

const TransactionsPerLoading = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPortalPage('Transactions Per Loading or Container Num. (Details in billings)'));
    }, [dispatch]);

    return <></>
}

export default TransactionsPerLoading;