import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Nav, Badge, Image, Button, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import SeaRoadBrand from '../assets/img/brand/sea-road-white.png';
import { isAuthenticated } from '../auth';
import { hasPermissions, hasRoles } from '../auth/UserPermissionTypes';
import './styles.scss';

export default (props) => {
  const { showSideMenu } = props;

  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(true);

  const toggleSidebar = () => {
    setShow(!show);
    showSideMenu(!show);
  };

  const CollapsableNavItem = ({ eventKey, title, icon, children = null }) => {
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{' '}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = ({ title, link, external, target, icon, image, badgeText, badgeBg = 'secondary', badgeColor = 'primary' }) => {
    const classNames = badgeText ? 'd-flex justify-content-start align-items-center justify-content-between' : '';
    const navItemClassName = link === pathname ? 'active' : '';
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon && (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{' '}
              </span>
            )}
            {image && <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" />}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText && (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">
              {badgeText}
            </Badge>
          )}
        </Nav.Link>
      </Nav.Item>
    );
  };

  const createLinks = (appRoutes) => {
    return appRoutes.map((prop, index) => {
      if (prop.collapse) {
        const rolesUnderViews = prop.views.reduce((acc, view) => acc.concat(view.roles), []);
        const permissionUnderViews = prop.views.reduce((acc, view) => acc.concat(view.permissions), []);

        if (hasRoles(rolesUnderViews) || hasPermissions(permissionUnderViews))
          return (
            <CollapsableNavItem key={index} eventKey={`${prop.state}`} title={prop.name} icon={prop.icon}>
              {createLinks(prop.views)}
            </CollapsableNavItem>
          );
      }

      if (hasRoles(prop.roles) && !prop.hidden)
        return <NavItem key={index} title={prop.name} link={`${prop.layout}${prop.path}`} icon={prop.icon} />;

      return null;
    });
  };

  return (
    <>
      <div className="side-bar-wrapper">
        <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
          <Navbar.Brand className="me-lg-5" as={Link} to={'/myboard/dashboard'}>
            <Image src={SeaRoadBrand} className="navbar-brand-light" />
          </Navbar.Brand>
          <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={toggleSidebar}>
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
        </Navbar>
        <CSSTransition timeout={300} classNames="sidebar-transition">
          <SimpleBar className={`sidebar ${show ? '' : 'hide'} d-md-block bg-charcoal text-white collapse`}>
            <div className={`sidebar-inner ${show ? '' : 'hide'} px-4 pt-3`}>
              <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                <div className="d-flex align-items-center">
                  <div className="user-avatar lg-avatar me-4">
                    <Image
                      src={isAuthenticated().user.photoUrl}
                      alt={isAuthenticated().user.name}
                      className="card-img-top rounded-circle border-white"
                    />
                  </div>
                  <div className="d-block">
                    <h6>Hi, {isAuthenticated().user.name}</h6>
                  </div>
                </div>
                <Nav.Link className="collapse-close d-md-none" onClick={toggleSidebar}>
                  <FontAwesomeIcon icon={faTimes} />
                </Nav.Link>
              </div>
              <Nav className="flex-column pt-3 pt-md-0">
                <Navbar.Brand to="/" className="me-lg-3 d-flex align-items-center admin-img">
                  <Image src={SeaRoadBrand} />
                </Navbar.Brand>
                {createLinks(props.routes)}
              </Nav>
            </div>
          </SimpleBar>
        </CSSTransition>
      </div>
      <Button
        onClick={toggleSidebar}
        className={`toggle-sidebar-button position-fixed bottom-0 start-0 m-3 ${show ? 'sidebar-visible' : 'sidebar-hidden'}`}
      >
        <FontAwesomeIcon icon={show ? faChevronLeft : faChevronRight} />
        <span className="hover-text">{show ? 'Hide Menu' : 'Show Menu'}</span>
      </Button>
    </>
  );
};
