import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { setPortalPage } from '../actions/blanket';
import { Row, Col, Nav, Tab } from "react-bootstrap";
import { 
    CANCELLED, CLIENT_NO_RESPONSE, EXPIRED_QUOTATION, FINAL_QUOTATION, 
    PENDING_CLIENT_SIGNATURE_QUOTATION, PENDING_GENERAL_MANAGER_APPROVAL, 
    PROFORMA_INVOICE, QUOTATION_LIST_AND_HISTORY_PROFORMA_INVOICE_APPROVER, 
    SIGNED_QUOTATION ,
    QUOTATION_LIST_AND_HISTORY_PENDING_GENERAL_MANAGER_APPROVER,
    QUOTATION_LIST_AND_HISTORY_FINAL_QUOTATION_APPROVER,
    QUOTATION_LIST_AND_HISTORY_VIEW_PENDING_CLIENT_SIGNATURE_QUOTATION,
    QUOTATION_LIST_AND_HISTORY_VIEW_SIGNED_QUOTATION,
    QUOTATION_LIST_AND_HISTORY_VIEW_CANCELLED_QUOTATION,
    QUOTATION_LIST_AND_HISTORY_VIEW_CLIENT_NO_RESPONSE_QUOTATION,
    QUOTATION_LIST_AND_HISTORY_VIEW_EXPIRED_QUOTATION
} from "../utils/constants";
import './styles.scss';
import SalesOrderDatatable from "./salesorderdatatable";
import productApi from '../services/product-service';
import clientApi from '../services/client-service';
import paymentTermsApi from '../services/payment-term-service';
import productCategoryApi from '../services/product-category-services';
import { hasPermission } from "../auth/UserPermissionTypes";

const SalesOrderList = () => {
    const dispatch = useDispatch();

    const { getProductOptions } = productApi;
    const { getClientNames } = clientApi;
    const { getClientsWithTerms } = paymentTermsApi;
    const { getProductCategoryNames } = productCategoryApi;

    const [productList, setProductList] = useState([]);
    const [clients, setClients] = useState([]);
    const [clientWithPaymentTerms, setClientWithPaymentTerms] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(PROFORMA_INVOICE); // Initialize to default active key
    const [productCategories, setProductCategories] = useState([]);

    useEffect(() => {
        dispatch(setPortalPage('Sales Quotation Management'));

        handleRefreshData();
    }, [dispatch]);

    const handleRefreshData = () => {
        getProductOptions().then(data => {
            setProductList(data.products);
        }).catch(e => console.log(e));

        getClientNames().then(data => {
            setClients(data.map(client => ({
                value: client._id,
                label: client.name
            })));
        }).catch(e => console.log(e));

        getClientsWithTerms().then(data => {
            setClientWithPaymentTerms(data);
        }).catch(e => console.log(e));

        getProductCategoryNames().then(data => {
            setProductCategories(data);
        }).catch(e => console.log(e));
    };

    const handleSelect = (key) => {
        setSelectedStatus(key);
        handleRefreshData();
    };

    const handleRefreshProductList = () => {
        getProductOptions().then(data => {
            setProductList(data.products);
        }).catch(e => console.log(e));
    };

    const tabConfig = [
        { key: 'PI', label: 'PI (Proforma Invoice)', status: PROFORMA_INVOICE, hasPermission: hasPermission(QUOTATION_LIST_AND_HISTORY_PROFORMA_INVOICE_APPROVER) },
        { key: 'PGMA', label: 'PGMA (Pending General Manager Approval)', status: PENDING_GENERAL_MANAGER_APPROVAL, hasPermission: hasPermission(QUOTATION_LIST_AND_HISTORY_PENDING_GENERAL_MANAGER_APPROVER) },
        { key: 'FQ', label: 'FQ (Final Quotation)', status: FINAL_QUOTATION, hasPermission: hasPermission(QUOTATION_LIST_AND_HISTORY_FINAL_QUOTATION_APPROVER) },
        { key: 'PCSQ', label: 'PCSQ (Pending Client Signature Quotation)', status: PENDING_CLIENT_SIGNATURE_QUOTATION, hasPermission: hasPermission(QUOTATION_LIST_AND_HISTORY_VIEW_PENDING_CLIENT_SIGNATURE_QUOTATION) },
        { key: 'SQ', label: 'SQ (Signed Quotation)', status: SIGNED_QUOTATION, hasPermission: hasPermission(QUOTATION_LIST_AND_HISTORY_VIEW_SIGNED_QUOTATION) },
        { key: 'CANCELLED', label: 'Cancelled', status: CANCELLED, hasPermission: hasPermission(QUOTATION_LIST_AND_HISTORY_VIEW_CANCELLED_QUOTATION) },
        { key: 'CNR', label: 'Client No Response', status: CLIENT_NO_RESPONSE, hasPermission: hasPermission(QUOTATION_LIST_AND_HISTORY_VIEW_CLIENT_NO_RESPONSE_QUOTATION) },
        { key: 'EQ', label: 'Expired Quotation', status: EXPIRED_QUOTATION, hasPermission: hasPermission(QUOTATION_LIST_AND_HISTORY_VIEW_EXPIRED_QUOTATION) }
    ];

    return (
        <Tab.Container activeKey={selectedStatus} onSelect={handleSelect}>
            <Row className="sales-status-tabs">
                <Col lg={12}>
                    <Nav className="nav-tabs">
                        {tabConfig.filter(tab => tab.hasPermission).map(tab => (
                            <Nav.Item key={tab.key}>
                                <Nav.Link eventKey={tab.key} className="mb-sm-3 mb-md-0">
                                    {tab.label}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Col>
                <Col lg={12}>
                    <Tab.Content>
                        {tabConfig.filter(tab => tab.hasPermission).map(tab => (
                            <Tab.Pane eventKey={tab.key} key={tab.key}>
                                {selectedStatus === tab.key && (
                                    <SalesOrderDatatable 
                                        status={tab.status} 
                                        clientCollection={clients} 
                                        productCollection={productList} 
                                        clientWithPaymentTermsCollection={clientWithPaymentTerms}
                                        productCategoryCollection={productCategories}
                                        refreshProductList={handleRefreshProductList}
                                    />
                                )}
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
}

export default SalesOrderList;
