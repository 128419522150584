import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Button, Table, Dropdown, ButtonGroup, Row, Col, Form, InputGroup } from '@themesberg/react-bootstrap';
import { faEllipsisH, faEye, faCalendar } from '@fortawesome/free-solid-svg-icons';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import auditApi from '../services/audit-service';
import { useForm } from 'react-hook-form';
import { setAuditLogsCount } from '../actions/auditlogs';
import { useDispatch, useSelector } from "react-redux";
import { defaultPageNumber } from "../utils/constants";
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import AuditDataHistory from "./auditdatahistory";
import PortalPagination from "../components/PortalPagination";
import { truncateDescription } from "../utils/formatter";

const AuditDataTable = () => {

    const { 
        getAuditPerformers, 
        getAuditDocuments, 
        getAllOperations,
        getCount,
        list
    } = auditApi;

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.blanket.showAdminLoader);
    const totalRecords = useSelector((state) => state.auditLogsState.auditLogsCount) || 0;
    const [selectedPage, setSelectedPage] = useState(defaultPageNumber);
    const [auditFilterDate, setAuditFilterDate] = useState();
    const [auditPerformers, setAuditPerformers] = useState([]);
    const [auditDocuments, setAuditDocuments] = useState([]);
    const [auditOperations, setAuditOperations] = useState([]);
    const [auditLogList, setAuditLogList] = useState([]);
    const [selectedAuditLog, setSelectedAuditLog] = useState(null);

    const formOptions = {
        mode: 'all'
    };

    const { register, getValues, reset } = useForm(formOptions);

    const handleFilterAuditLogs = async (pageNumber = 1) => {
        try {
            setSelectedPage(pageNumber);
    
            // Get filter values from the form
            const { auditFilterPerformerId, auditFilterDocument, auditFilterOperation } = getValues();
    
            // Format the date if provided
            const formattedDate = auditFilterDate ? moment(auditFilterDate).format('YYYY-MM-DD') : undefined;
    
            // Fetch the count of audit logs
            const countResponse = await getCount(auditFilterPerformerId, auditFilterDocument, auditFilterOperation, formattedDate);
            dispatch(setAuditLogsCount(countResponse.count));
    
            // Fetch the list of audit logs
            const listResponse = await list(pageNumber, auditFilterPerformerId, auditFilterDocument, auditFilterOperation, formattedDate);
            setAuditLogList(listResponse.records);
        } catch (error) {
            console.log("Error while filtering audit logs: ", error);
        }
    };

    const resetFilters = () => {
        // Reset form values using reset from react-hook-form
        reset({
            auditFilterPerformerId: '',
            auditFilterDocument: '',
            auditFilterOperation: ''
        });
    
        // Reset the state for the filters and page number
        setAuditFilterDate(null);
        setSelectedPage(1);
    
        // Fetch the audit logs with no filters applied, and page number set to 1
        getCount().then((res) => {
            dispatch(setAuditLogsCount(res.count));
            list(defaultPageNumber).then((res) => {
              setAuditLogList(res.records);
            });
        });
    };

    const handleStartDateChange = (date) => {
        setAuditFilterDate(date);
    };

    const handlePageEvent = (pageNumber) => {
        handleFilterAuditLogs(pageNumber);
    };

    useEffect(() => {
        getAuditPerformers().then(res => {
            setAuditPerformers(res);
        });

    }, [getAuditPerformers, setAuditPerformers])

    useEffect(() => {
        getAuditDocuments().then(res => {
            setAuditDocuments(res);
        });

    }, [getAuditDocuments, setAuditDocuments])

    useEffect(() => {
        getAllOperations().then(res => {
            setAuditOperations(res);
        });

    }, [getAllOperations, setAuditOperations])

    useEffect(() => {
        getCount().then((res) => {
          dispatch(setAuditLogsCount(res.count));
          list(defaultPageNumber).then((res) => {
            setAuditLogList(res.records);
          });
        });
    }, [getCount, dispatch, list, setAuditLogList]);

    return (
        <Card border="light" className="table-wrapper table-responsive shadow-sm">
            <Card.Body className="pt-0">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0 search-block-width-600">
                        <p className="mb-0">Stay informed with detailed audit logs of all activities and changes.</p>
                    </div>
                </div>
                <div className="table-settings mb-5">
                    <Row className="justify-content-between align-items-center">
                        <Col md={3}>
                            <Form.Group id="filter-user">
                                <Form.Label className="light-font">Filter User</Form.Label>
                                <Form.Select 
                                    {...register('auditFilterPerformerId')}
                                >
                                    <option value="">Select User</option>
                                    {auditPerformers.map((item, i) => (
                                        <option key={i} value={item._id}>
                                            {item.firstname} - {item.lastname}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group id="filter-type">
                                <Form.Label className="light-font">Filter Document</Form.Label>
                                <Form.Select 
                                    {...register('auditFilterDocument')}
                                >
                                    <option value="">Select Document</option>
                                    {auditDocuments.map((item, i) => (
                                        <option key={i} value={item.modelName}>
                                            {item.modelName}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group id="filter-action">
                                <Form.Label className="light-font">Filter Action</Form.Label>
                                <Form.Select 
                                    {...register('auditFilterOperation')}
                                >
                                    <option value="">Select Action</option>
                                    {auditOperations.map((item, i) => (
                                        <option key={i} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group id="filter-date">
                                <Form.Label className="light-font">Filter Date</Form.Label>
                                <Datetime
                                    timeFormat={false}
                                    onChange={handleStartDateChange}
                                    closeOnSelect={true}
                                    renderInput={(props, openCalendar) => (
                                    <InputGroup>
                                        <InputGroup.Text>
                                        <FontAwesomeIcon icon={faCalendar} />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            name="auditFilterDate"
                                            value={auditFilterDate ? moment(auditFilterDate).format('MM/DD/YYYY') : ''}
                                            placeholder="Select date"
                                            onFocus={openCalendar}
                                            onChange={(e) => setAuditFilterDate(e.target.value)}
                                        />
                                    </InputGroup>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="justify-content-between align-items-center mt-3">
                        <Col md={3}>
                            <Button variant="primary" type="button" onClick={() => handleFilterAuditLogs()}>
                                Search
                            </Button>
                            <Button className='ms-1' variant="secondary" type="button" onClick={() => resetFilters()}>
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Table hover className="billing-table-list align-items-center">
                    <thead>
                        <tr>
                          <th className="border-bottom">User</th>
                          <th className="border-bottom">Entity Id</th>
                          <th className="border-bottom">Action</th>
                          <th className="border-bottom">Type</th>
                          <th className="border-bottom">Timestamp</th>
                          <th className="border-bottom">Description</th>
                          <th className="border-bottom"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {auditLogList.map((item, index) => (
                            <Fragment key={index}>
                                <tr>
                                    <td>
                                        <span className="fw-normal">
                                            {loading && selectedAuditLog == null ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                            <>{`${item.changedBy.firstname} ${item.changedBy.lastname}`}</>
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading && selectedAuditLog == null ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                            item.documentId
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading && selectedAuditLog == null ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                            item.operation
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading && selectedAuditLog == null ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                            item.modelName
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading && selectedAuditLog == null ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                moment(item.changeDate).format('MMM. DD, YYYY hh:mm A')
                                            )}
                                        </span>
                                    </td>
                                    <td title={item.description.length > 100 ? item.description : ''}>
                                        <span className="fw-normal">
                                            {loading && selectedAuditLog == null ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                truncateDescription(item.description, 100)
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading && selectedAuditLog == null ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                <td>
                                                    <Dropdown as={ButtonGroup}>
                                                        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                                            <span className="icon icon-sm">
                                                                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                                                            </span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="more-left-dropdown-menu">
                                                            <Dropdown.Item onClick={() => setSelectedAuditLog(item)}>
                                                                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            )}
                                        </span>
                                    </td>
                                </tr>
                                {selectedAuditLog && selectedAuditLog._id === item._id && (
                                    <tr>
                                        <td colSpan={7}>
                                            <AuditDataHistory 
                                                oldData={item.previousValues} 
                                                newData={item.newValues} 
                                                hideLogs={setSelectedAuditLog}
                                                description={item.description} 
                                            />
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        ))}
                    </tbody>
                </Table>
                {!loading && (
                    <PortalPagination totalRecords={totalRecords} currentPage={selectedPage} pagingEvent={handlePageEvent} />
                )}
            </Card.Body>
        </Card>
    )
}

export default AuditDataTable;