import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPortalPage } from '../actions/blanket';

const ContainerCategory = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPortalPage('List of Container Category (LCL & FCL) Count'));
    }, [dispatch]);

    return <></>
}

export default ContainerCategory;