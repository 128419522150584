import React, { useEffect, useState } from 'react';
import { Row, Col } from '@themesberg/react-bootstrap';
import { Button, Modal, Form } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './styles.scss';
import rateCategoryApi from '../services/rate-category-service';
import rateBracketApi from '../services/rate-bracket-service';
import { showError, showOk } from '../components/Alerts';

const RateBracketForm = (props) => {
    const { 
        rateBracketData,
        cancelEdit,
        refreshList
    } = props;

    const { getRateCategories } = rateCategoryApi;

    const { createRateBracket, updateRateBracket } = rateBracketApi;

    const { Header, Dialog, Title, Body, Footer } = Modal;
    const [loading, setLoading] = useState(false);
    const [rateCategories, setRateCategories] = useState([]);

    const rateBracketSchema = Yup.object().shape({
        rateCategoryId: Yup.string().required('Rate category is required'),
        name: Yup.string().required('Bracket name is required'),
        rate: Yup.number().typeError('Rate must be a number').min(0, 'Rate must be non-negative').required('Rate is required'),
        startQuantity: Yup.number().typeError('Start quantity must be a number').min(0, 'Start quantity must be non-negative').required('Start quantity is required'),
        endQuantity: Yup.number()
            .typeError('End quantity must be a number')
            .min(0, 'End quantity must be non-negative')
            .required('End quantity is required')
            .test('is-greater', 'End quantity must be greater than or equal to start quantity', function (value) {
                const { startQuantity } = this.parent;
                return value >= startQuantity;
            }),
    });

    const formOptions = {
        resolver: yupResolver(rateBracketSchema),
        mode: 'all',
        defaultValues: {
            rateCategoryId: rateBracketData?.rateCategory._id || '',
            name: rateBracketData?.name || '',
            rate: rateBracketData?.rate || 0,
            startQuantity: rateBracketData?.startQuantity || 0,
            endQuantity: rateBracketData?.endQuantity || 0,
        }
    };

    const { register, handleSubmit, getValues, setValue, formState, reset } = useForm(formOptions);
    const { errors } = formState;

    const handleSaveRateBracket = () => {
        setLoading(true);

        let formData = new FormData();

        var formValues = getValues();

        for (const key in formValues) {
            if (key === 'field') {
                let formValue = formValues[key][1];
                formData.append(key, formValue);
            } else {
                let formValue = formValues[key];
                formData.append(key, formValue);
            }
        }

        if(rateBracketData){
            formData.append('rateBracketId', rateBracketData._id);

            updateRateBracket(formData)
                .then(() => {
                    showOk('Rate bracket updated');
                    reset();
                    refreshList();
                    cancelEdit();
                    setLoading(false);
                })
                .catch((e) => {
                    showError(e?.data?.error);
                    setLoading(false);
                });
        }
        else
        {
            createRateBracket(formData)
            .then(() => {
                showOk('Rate bracket saved');
                reset();
                refreshList();
                cancelEdit();
                setLoading(false);
            })
            .catch((e) => {
                showError(e?.data?.error);
                setLoading(false);
            });
        }
    }

    const handleRateChange = (e, rateProp) => {
        const value = e.target.value;
        if (value === '') {
            setValue(rateProp, null);
        } else if (!isNaN(value) && parseFloat(value) >= 0) {
            setValue(rateProp, parseFloat(value));
        } else {
            setValue(rateProp, '');
        }
    };

    useEffect(() => {
        getRateCategories().then(data => {
            setRateCategories(data);
        })
        .catch(e => console.log(e));
    }, [getRateCategories]);

    return (
        <Modal as={Dialog} backdropClassName="" centered show={true}>
            <Form onSubmit={handleSubmit(handleSaveRateBracket)}>
                <Header>
                    <Title className="h6">{`${rateBracketData ? 'Update' : 'Create'}`} - Rate Bracket</Title>
                    <Button variant="close" aria-label="Close" onClick={() => cancelEdit()} />
                </Header>
                <Body>
                    {rateCategories.length > 0 && (
                        <Row>
                            <Col md={12} className="mb-3">
                                <Form.Group id="rate-category">
                                    <Form.Label className="light-font">Rate Category</Form.Label>
                                    <Form.Select 
                                        {...register('rateCategoryId')}
                                    >
                                        <option value="">Select rate category</option>
                                        {rateCategories.map((item, i) => (
                                            <option key={i} value={item._id}>
                                                {item.name} - {item.categoryUnit}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {errors.rateCategoryId?.message && (
                                        <small className="invalid-fields">{errors.rateCategoryId.message}</small>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col md={12} className="mb-4">
                            <Form.Group id="rate-bracket-name">
                                <Form.Label className="light-font">Bracket name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Bracket name"
                                    {...register('name')}
                                />
                                {errors.name?.message && (
                                    <small className="invalid-fields">{errors.name.message}</small>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="mb-4">
                            <Form.Group id="rate-bracket-start-quantity">
                                <Form.Label className="light-font">Start Quantity</Form.Label>
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    name="startQuantity"
                                    placeholder="0"
                                    {...register('startQuantity', { onChange: (e) => handleRateChange(e, 'startQuantity') })}
                                    min="0"
                                />
                                {errors.startQuantity?.message && (
                                    <small className="invalid-fields">{errors.startQuantity.message}</small>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="mb-4">
                            <Form.Group id="rate-bracket-end-quantity">
                                <Form.Label className="light-font">End Quantity</Form.Label>
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    name="endQuantity"
                                    placeholder="0"
                                    {...register('endQuantity', { onChange: (e) => handleRateChange(e, 'endQuantity') })}
                                    min="0"
                                />
                                {errors.endQuantity?.message && (
                                    <small className="invalid-fields">{errors.endQuantity.message}</small>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="mb-4">
                            <Form.Group id="rate-bracket-rate">
                                <Form.Label className="light-font">Rate</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="rate"
                                    placeholder="00.00"
                                    {...register('rate', { onChange: (e) => handleRateChange(e, 'rate') })}
                                    min="0"
                                    step="0.01"
                                />
                                {errors.rate?.message && (
                                    <small className="invalid-fields">{errors.rate.message}</small>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </Body>
                <Footer>
                    <Button variant="primary" onClick={() => cancelEdit()}>
                        Close
                    </Button>
                    <Button type="submit" disabled={loading} className="ms-auto" variant="secondary">
                        Save
                    </Button>
                </Footer>
            </Form>
        </Modal>
    );
}

export default RateBracketForm;
