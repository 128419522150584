export const ADMIN_PORTAL_PAGE = 'ADMIN_PORTAL_PAGE';
export const SHOW_ADMIN_PORTAL_LOADER = 'SHOW_ADMIN_PORTAL_LOADER';
export const HIDE_ADMIN_PORTAL_LOADER = 'HIDE_ADMIN_PORTAL_LOADER';

// USER ACTION TYPES
export const USER_COUNT = 'USER_COUNT';
export const USER_TOTAL_PAGES = 'USER_TOTAL_PAGES';

// CLIENT ACTION TYPES
export const CLIENT_COUNT = 'CLIENT_COUNT';
export const CLIENT_TOTAL_PAGES = 'CLIENT_TOTAL_PAGES';
export const CLIENT_BILLINGS_COUNT = 'CLIENT_BILLINGS_COUNT';
export const CLIENT_BILLINGS_TOTAL_PAGES = 'CLIENT_BILLINGS_TOTAL_PAGES';
export const CLIENT_SALES_QUOTATION_COUNT = 'CLIENT_SALES_QUOTATION_COUNT';
export const CLIENT_SALES_QUOTATION_TOTAL_PAGES = 'CLIENT_SALES_QUOTATION_TOTAL_PAGES';

// RATE BRACKET ACTION TYPES
export const RATE_BRACKET_COUNT = 'RATE_BRACKET_COUNT';
export const RATE_BRACKET_TOTAL_PAGES = 'RATE_BRACKET_TOTAL_PAGES';


// BILLING ACTION TYPES
export const APPROVE_BILLINGS_COUNT = 'APPROVE_BILLINGS_COUNT';
export const APPROVE_BILLINGS_TOTAL_PAGES = 'APPROVE_BILLINGS_TOTAL_PAGES';
export const CANCELLED_BILLINGS_COUNT = 'CANCELLED_BILLINGS_COUNT';
export const CANCELLED_BILLINGS_TOTAL_PAGES = 'CANCELLED_BILLINGS_TOTAL_PAGES';

// AUDIT LOGS ACTION TYPES
export const AUDIT_LOGS_COUNT = 'AUDIT_LOGS_COUNT';
export const AUDIT_LOGS_TOTAL_PAGES = 'AUDIT_LOGS_TOTAL_PAGES';

// PRODUCT ACTION TYPES
export const PRODUCT_COUNT = 'PRODUCT_COUNT';
export const PRODUCT_TOTAL_PAGES = 'PRODUCT_TOTAL_PAGES';

// PAYMENT TERM ACTION TYPES
export const PAYMENT_TERM_COUNT = 'PAYMENT_TERM_COUNT';
export const PAYMENT_TERM_TOTAL_PAGES = 'PAYMENT_TERM_TOTAL_PAGES';

// SALES ORDER QUOTATION ACTION TYPES
export const QUOTATION_COUNT = 'QUOTATION_COUNT';
export const QUOTATION_TOTAL_PAGES = 'QUOTATION_TOTAL_PAGES';


