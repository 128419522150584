import React from "react";
import { Card, Row, Col } from "@themesberg/react-bootstrap";
import './styles.scss';
import GeneratedBillingItems from "./generatedbillingitems";
import { formatCurrency } from "../utils/formatter";
import { CANCELLED } from "../utils/constants";

const BillingDocumentDetail = (props) => {
    const { pendingBillingList, billingDocumentData, refreshList, canModify } = props;

    const getAppliedValues = () => {
        if (billingDocumentData.appliedRateData && Object.keys(billingDocumentData.appliedRateData).length !== 0) {
            return {
                rate: billingDocumentData.appliedRateData.rate,
                categoryUnit: billingDocumentData.appliedRateData.rateCategory.categoryUnit
            };
        } else {
            const fixRate = billingDocumentData.appliedClientSpecialRateData?.fixRate;
            const bracketRate = billingDocumentData.appliedClientSpecialRateData?.bracketRate;
            const appliedRate = fixRate > 0 ? fixRate : bracketRate;
            return {
                rate: appliedRate,
                categoryUnit: billingDocumentData.appliedClientSpecialRateData.rateCategory.categoryUnit
            };
        }
    };

    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className="d-none d-sm-block total-billable-amount mb-4">
                        <h5>Total Billable Amount:</h5>
                        <h2 className="fs-1 fw-extrabold mb-1">{formatCurrency(parseFloat(billingDocumentData.totalBillableAmount)) || 0.00}</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <Card border="light" className="shadow-sm">
                        <Card.Body>
                            <h5 className="mb-3">Client Information</h5>
                            <p>Reference: <b>{billingDocumentData.clientRef}</b></p>
                            <p>Tracking Number: <b>{billingDocumentData.trackingReference}</b></p>
                            <p>First Name: <b>{billingDocumentData.clientData.firstName}</b></p>
                            <p>Last Name: <b>{billingDocumentData.clientData.lastName}</b></p>
                            <p>Email: <b>{billingDocumentData.clientData.email}</b></p>
                            <p>Contact: <b>{billingDocumentData.clientData.mobileNumber}</b></p>
                            <p>Display Name: <b>{billingDocumentData.clientData.displayName}</b></p>
                            <p>&nbsp;</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card border="light" className="shadow-sm">
                        <Card.Body>
                            <h5 className="mb-3">Sum & Applied Rate</h5>
                            <p>Sum of CBM: <b>{parseFloat(billingDocumentData.sumOfCBM).toFixed(2)}</b></p>
                            <p>Sum of Sensitive: <b>{parseFloat(billingDocumentData.sumOfSensitiveCharge).toFixed(2)}</b></p>
                            <p>Sum of Forklift: <b>{parseFloat(billingDocumentData.sumOfForklift).toFixed(2)}</b></p>
                            <p>Sum of Inland: <b>{parseFloat(billingDocumentData.sumOfInland).toFixed(2)}</b></p>
                            <p>Sum of Gross Wt: <b>{parseFloat(billingDocumentData.sumOfGrossWeight).toFixed(2)}</b></p>
                            <p>Applied Rate: <b>{formatCurrency(parseFloat(getAppliedValues().rate))}</b></p>
                            <p>Applied Unit: <b>{getAppliedValues().categoryUnit}</b></p>
                            <p>&nbsp;</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card border="light" className="shadow-sm">
                        <Card.Body>
                            <h5 className="mb-3">Total Records</h5>
                            <p>Freight Amount: <b>{formatCurrency(parseFloat(billingDocumentData.grossAmount))}</b></p>
                            <p>Total of Sensitive: <b>{formatCurrency(parseFloat(billingDocumentData.totalSensitiveCharge))}</b></p>
                            <p>Total of Forklift: <b>{formatCurrency(parseFloat(billingDocumentData.totalForkliftCharge))}</b></p>
                            <p>Total of Inland: <b>{formatCurrency(parseFloat(billingDocumentData.totalInlandCharge))}</b></p>
                            <p>Total Custom Clearance: <b>{formatCurrency(parseFloat(billingDocumentData.totalOfCustomsClearance))}</b></p>
                            <p>Total of OD4D: <b>{formatCurrency(parseFloat(billingDocumentData.totalOfoc4d))}</b></p>
                            <p>Exceed Max Wt?: <b>{billingDocumentData.exceededMaxWeight ? 'Yes' : 'No'}</b></p>
                            <p>&nbsp;</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card border="light" className="shadow-sm">
                        <Card.Body>
                            <h5 className="mb-3">More Information</h5>
                            <p>Container No.: <b>{billingDocumentData.containerNumber}</b></p>
                            <p>Invoice No.: <b>{billingDocumentData.invoiceNumber}</b></p>
                            <p>Billing Date: <b>{billingDocumentData.billingDateAsString}</b></p>
                            <p>USD conversion: <b>{formatCurrency(parseFloat(billingDocumentData.containerUSDConversion))}</b></p>
                            <p>RMB conversion: <b>{formatCurrency(parseFloat(billingDocumentData.containerRMBConversion))}</b></p>
                            <p>Status: <b>{billingDocumentData.status}</b></p>
                            <p>Quotient: <b>{billingDocumentData.quotient.toFixed(2)}</b></p>
                            {billingDocumentData.status === CANCELLED ? (
                                <p>Cancel Reason: <b>{billingDocumentData.cancelReason}</b></p>
                            ) : (
                                <p>&nbsp;</p>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <GeneratedBillingItems
                        pendingBillingList={pendingBillingList}
                        billingItems={billingDocumentData.items} 
                        refreshList={refreshList} 
                        canModify={canModify}
                        billingStatus={billingDocumentData.status}
                    />
                </Col>
            </Row>
        </>
    );
};

export default BillingDocumentDetail;
