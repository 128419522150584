import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPortalPage } from '../actions/blanket';

const CostMatchingRevenue = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPortalPage('Cost Matching Revenue or Cost Sheet Summary'));
    }, [dispatch]);

    return <></>
}

export default CostMatchingRevenue;