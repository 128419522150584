import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPortalPage } from '../actions/blanket';

const RateCounts = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPortalPage('Rate Counts (With Total CBM, Amount & Percentage)'));
    }, [dispatch]);

    return <></>
}

export default RateCounts;