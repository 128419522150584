// DEFAULT TIMEZONE
export const DEFAULT_TIMZONE = 'Asia/Manila';
export const DEFAULT_PH_LOCALE_DATE_FORMAT = 'en-PH'

//SYSTEM ROLES
export const ALL = 'All';
export const SUPER_ADMIN = 'SUPER_ADMIN';
export const MEMBER = 'MEMBER';
export const USER_MANAGER = 'USER_MANAGER';
export const CLIENT_MANAGER = 'CLIENT_MANAGER';
export const RATE_MANAGER = 'RATE_MANAGER';
export const PROCESS_BILLING_MANAGER = 'PROCESS_BILLING_MANAGER';
export const BILLING_TRANSACTIONS_AND_HISTORY = "BILLING_TRANSACTIONS_AND_HISTORY";
export const PRODUCT_MANAGER = 'PRODUCT_MANAGER';
export const SALES_QUOTATION = 'SALES_QUOTATION';
export const SALES_QUOTATION_LIST_AND_HISTORY = "SALES_QUOTATION_LIST_AND_HISTORY";

//SYSTEM PERMISSIONS
export const USER_MANAGER_UPDATE = 'USER_MANAGER_UPDATE';
export const USER_MANAGER_ADD = 'USER_MANAGER_ADD';
export const USER_MANAGER_CAN_ACTIVATE = "USER_MANAGER_CAN_ACTIVATE";
export const USER_MANAGER_DELETE = 'USER_MANAGER_DELETE';

export const CLIENT_MANAGER_ADD = 'CLIENT_MANAGER_ADD';
export const CLIENT_MANAGER_UPDATE = 'CLIENT_MANAGER_UPDATE';
export const CLIENT_MANAGER_DELETE = 'CLIENT_MANAGER_DELETE';
export const RATE_MANAGER_ADD = 'RATE_MANAGER_ADD';
export const RATE_MANAGER_UPDATE = 'RATE_MANAGER_UPDATE';
export const RATE_MANAGER_DELETE = 'RATE_MANAGER_DELETE';

export const CLIENT_MANAGER_MANAGE_AGREEMENTS = 'CLIENT_MANAGER_MANAGE_AGREEMENTS';
export const CLIENT_MANAGER_MANAGE_NOTIFICATION_CHANNEL = 'CLIENT_MANAGER_MANAGE_NOTIFICATION_CHANNEL';
export const CLIENT_MANAGER_VIEW_SPECIAL_RATES = 'CLIENT_MANAGER_VIEW_SPECIAL_RATES';
export const CLIENT_MANAGER_ADD_SPECIAL_RATES = 'CLIENT_MANAGER_ADD_SPECIAL_RATES';
export const CLIENT_MANAGER_UPDATE_SPECIAL_RATES = 'CLIENT_MANAGER_UPDATE_SPECIAL_RATES';
export const CLIENT_MANAGER_DELETE_SPECIAL_RATES = 'CLIENT_MANAGER_DELETE_SPECIAL_RATES';
export const CLIENT_MANAGER_DOWNLOAD_ATTACHMENTS = 'CLIENT_MANAGER_DOWNLOAD_ATTACHMENTS';
export const CLIENT_MANAGER_VIEW_PAYMENT_TERMS = 'CLIENT_MANAGER_VIEW_PAYMENT_TERMS';
export const CLIENT_MANAGER_UPDATE_PAYMENT_TERMS = 'CLIENT_MANAGER_UPDATE_PAYMENT_TERMS';
export const CLIENT_MANAGER_ADD_PAYMENT_TERMS = 'CLIENT_MANAGER_ADD_PAYMENT_TERMS';
export const CLIENT_MANAGER_VIEW_TRANSACTION_HISTORY = 'CLIENT_MANAGER_VIEW_TRANSACTION_HISTORY';
export const CLIENT_MANAGER_VIEW_BILLING_TRANSACTIONS = 'CLIENT_MANAGER_VIEW_BILLING_TRANSACTIONS';
export const CLIENT_MANAGER_VIEW_SALES_QUOTATION = 'CLIENT_MANAGER_VIEW_SALES_QUOTATION';

export const RATE_MANAGER_VIEW_RATE_CATEGORY = "RATE_MANAGER_VIEW_RATE_CATEGORY";
export const RATE_MANAGER_ADD_RATE_CATEGORY = "RATE_MANAGER_ADD_RATE_CATEGORY";
export const RATE_MANAGER_UPDATE_RATE_CATEGORY = "RATE_MANAGER_UPDATE_RATE_CATEGORY";
export const RATE_MANAGER_DELETE_RATE_CATEGORY = "RATE_MANAGER_DELETE_RATE_CATEGORY";

export const PROCESS_BILLING_MANAGER_IMPORT_BILLING_DOCUMENT = "PROCESS_BILLING_MANAGER_IMPORT_BILLING_DOCUMENT";
export const PROCESS_BILLING_MANAGER_APPROVED_BILLING = "PROCESS_BILLING_MANAGER_APPROVED_BILLING";
export const PROCESS_BILLING_MANAGER_CANCEL_BILLING = "PROCESS_BILLING_MANAGER_CANCEL_BILLING";
export const PROCESS_BILLING_MANAGER_UPDATE_BILLING_ITEM = "PROCESS_BILLING_MANAGER_UPDATE_BILLING_ITEM";
export const PROCESS_BILLING_MANAGER_MOVE_BILLING_ITEM = "PROCESS_BILLING_MANAGER_MOVE_BILLING_ITEM";
export const PROCESS_BILLING_MANAGER_PREVIEW_BILLING = "PROCESS_BILLING_MANAGER_PREVIEW_BILLING";

export const BILLING_TRANSACTIONS_AND_HISTORY_VIEW_APPROVED_BILLING = "BILLING_TRANSACTIONS_AND_HISTORY_VIEW_APPROVED_BILLING";
export const BILLING_TRANSACTIONS_AND_HISTORY_VIEW_CANCELLED_BILLING = "BILLING_TRANSACTIONS_AND_HISTORY_VIEW_CANCELLED_BILLING";
export const BILLING_TRANSACTIONS_AND_HISTORY_VIEW_PREVIEW_BILLING = "BILLING_TRANSACTIONS_AND_HISTORY_VIEW_PREVIEW_BILLING";
export const BILLING_TRANSACTIONS_AND_HISTORY_DOWNLOAD_BILLING = "BILLING_TRANSACTIONS_AND_HISTORY_DOWNLOAD_BILLING";
export const BILLING_TRANSACTIONS_AND_HISTORY_SEND_BILLING = "BILLING_TRANSACTIONS_AND_HISTORY_SEND_BILLING";

export const PRODUCT_MANAGER_ADD_PRODUCT = "PRODUCT_MANAGER_ADD_PRODUCT";
export const PRODUCT_MANAGER_UPDATE_PRODUCT = "PRODUCT_MANAGER_UPDATE_PRODUCT";
export const PRODUCT_MANAGER_DELETE_PRODUCT = "PRODUCT_MANAGER_DELETE_PRODUCT";
export const PRODUCT_MANAGER_VIEW_PRODUCT_CATEGORY = "PRODUCT_MANAGER_VIEW_PRODUCT_CATEGORY";
export const PRODUCT_MANAGER_ADD_PRODUCT_CATEGORY = "PRODUCT_MANAGER_ADD_PRODUCT_CATEGORY";
export const PRODUCT_MANAGER_UPDATE_PRODUCT_CATEGORY = "PRODUCT_MANAGER_UPDATE_PRODUCT_CATEGORY";
export const PRODUCT_MANAGER_DELETE_PRODUCT_CATEGORY = "PRODUCT_MANAGER_DELETE_PRODUCT_CATEGORY";
export const PRODUCT_MANAGER_MANAGE_PRODUCT_RESOURCE = "PRODUCT_MANAGER_MANAGE_PRODUCT_RESOURCE";
export const PRODUCT_MANAGER_DELETE_PRODUCT_RESOURCE = "PRODUCT_MANAGER_DELETE_PRODUCT_RESOURCE";

export const SALES_QUOTATION_CREATE_QUOTATION = "SALES_QUOTATION_CREATE_QUOTATION";
export const SALES_QUOTATION_ADD_PRODUCT = "SALES_QUOTATION_ADD_PRODUCT";

export const QUOTATION_LIST_AND_HISTORY_PROFORMA_INVOICE_APPROVER = "QUOTATION_LIST_AND_HISTORY_PROFORMA_INVOICE_APPROVER";
export const QUOTATION_LIST_AND_HISTORY_PENDING_GENERAL_MANAGER_APPROVER = "QUOTATION_LIST_AND_HISTORY_PENDING_GENERAL_MANAGER_APPROVER";
export const QUOTATION_LIST_AND_HISTORY_FINAL_QUOTATION_APPROVER = "QUOTATION_LIST_AND_HISTORY_FINAL_QUOTATION_APPROVER";
export const QUOTATION_LIST_AND_HISTORY_EDIT_QUOTATION = "QUOTATION_LIST_AND_HISTORY_EDIT_QUOTATION";
export const QUOTATION_LIST_AND_HISTORY_CANCEL_QUOTATION = "QUOTATION_LIST_AND_HISTORY_CANCEL_QUOTATION";
export const QUOTATION_LIST_AND_HISTORY_PREVIEW_QUOTATION = "QUOTATION_LIST_AND_HISTORY_PREVIEW_QUOTATION";
export const QUOTATION_LIST_AND_HISTORY_DOWNLOAD_QUOTATION = "QUOTATION_LIST_AND_HISTORY_DOWNLOAD_QUOTATION";
export const QUOTATION_LIST_AND_HISTORY_SEND_QUOTATION_TO_CLIENT = "QUOTATION_LIST_AND_HISTORY_SEND_QUOTATION_TO_CLIENT";
export const QUOTATION_LIST_AND_HISTORY_MOVE_TO_SIGNED_QUOTATION = "QUOTATION_LIST_AND_HISTORY_MOVE_TO_SIGNED_QUOTATION";
export const QUOTATION_LIST_AND_HISTORY_MOVE_TO_NO_RESPONSE = "QUOTATION_LIST_AND_HISTORY_MOVE_TO_NO_RESPONSE";
export const QUOTATION_LIST_AND_HISTORY_VIEW_PENDING_CLIENT_SIGNATURE_QUOTATION = "QUOTATION_LIST_AND_HISTORY_VIEW_PENDING_CLIENT_SIGNATURE_QUOTATION";
export const QUOTATION_LIST_AND_HISTORY_VIEW_SIGNED_QUOTATION = "QUOTATION_LIST_AND_HISTORY_VIEW_SIGNED_QUOTATION";
export const QUOTATION_LIST_AND_HISTORY_VIEW_CANCELLED_QUOTATION = "QUOTATION_LIST_AND_HISTORY_VIEW_CANCELLED_QUOTATION";
export const QUOTATION_LIST_AND_HISTORY_VIEW_CLIENT_NO_RESPONSE_QUOTATION = "QUOTATION_LIST_AND_HISTORY_VIEW_CLIENT_NO_RESPONSE_QUOTATION";
export const QUOTATION_LIST_AND_HISTORY_VIEW_EXPIRED_QUOTATION = "QUOTATION_LIST_AND_HISTORY_VIEW_EXPIRED_QUOTATION";

//JWT token constants
export const JWT_TOKEN = 'jwt';

export const defaultPageNumber = 1;

export const defaultTruncateLength = 20;

// Friendly error message and contact support
export const friendlyErrorMessage = 'Sorry, something went wrong. Please contact support.';

// Moment properties
export const CUSTOM_PAY_DATE_FORMAT = 'MMM DD, YYYY';
export const CUSTOM_OUTPUT_DATE_FORMAT = 'YYYY-MM-DD';
export const MOMENT_DAYS = 'days';
export const DAYS_COLLECTION = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const REGULAR_HOLIDAY_COLOR = '#FA5252';
export const SPECIAL_HOLIDAY_COLOR = '#2037ae';

//SYSTEM STATUS
export const APPROVED = "APPROVED";
export const PENDING = "PENDING";
export const CANCELLED = "CANCELLED"; // ALSO USED IN SALES ORDER STATUSES
export const READY_FOR_INVOICE = "READYFORINVOICE";

//PAYMENT METHODS
export const ALL_PAYMENT_METHOD = "All";
export const BANK_TRANSFER = "Bank Transfer";
export const CREDIT_CARD = "Credit Card";
export const CHECK = "Check";
export const CASH = "Cash";

// SYSTEM PAYMENT TYPES
export const REGULAR_PAYMENT = "Regular Payment";
export const INSTALLMENT = "Installment";
export const DISCOUNT = "Discount";

// SYSTEM SHIPMENT TYPES
export const shipmentTypes = {
    LCL: "Less than container load",
    FCL: "Full container load"
};


// SYSTEM SALES ORDER STATUSES
export const PROFORMA_INVOICE = "PI";
export const PENDING_GENERAL_MANAGER_APPROVAL = "PGMA";
export const FINAL_QUOTATION = "FQ";
export const SIGNED_QUOTATION = "SQ";
export const PENDING_CLIENT_SIGNATURE_QUOTATION = "PCSQ";
export const CLIENT_NO_RESPONSE = "CNR";
export const EXPIRED_QUOTATION = "EQ";


