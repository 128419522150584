import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPortalPage } from '../actions/blanket';

const SourcingTransactions = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPortalPage('List of Sourcing Transaction (With Revenue, COS, Gross Profit & Percentage of GP)'));
    }, [dispatch]);

    return <></>
}

export default SourcingTransactions;