import React, { useEffect, useState } from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import SeaRoadBrand from '../assets/img/brand/sea-road-dark.png';
import './styles.scss';
import { DEFAULT_PH_LOCALE_DATE_FORMAT, shipmentTypes } from "../utils/constants";

const SalesQuotationPreview = (props) => {
    const { quotationData, productCollection } = props;
    const [quotationProducts, setQuotationProducts] = useState([]);

    // Format the billing date using Philippines date format
    const validUntilDate = new Date(quotationData.validDateUntil);
    const formattedValidUntilDate = validUntilDate.toLocaleDateString(DEFAULT_PH_LOCALE_DATE_FORMAT, {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
    });

    const quotationDate = new Date(quotationData.quotationDate);
    const formattedQuotationDate = quotationDate.toLocaleDateString(DEFAULT_PH_LOCALE_DATE_FORMAT, {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
    });

    const formatPrice = (price) => {
        return parseFloat(price)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    };

    const getShipmentTypeDescription = (shipmentType) => {
        return `(${shipmentType}) ${shipmentTypes[shipmentType]}` || "Unknown shipment type";
    };

    useEffect(() => {
        const fetchProductDetails = () => {
            const productsWithDetails = quotationData.generatedQuotationProducts.map(quotationProduct => {
                const productDetails = productCollection.find(product => product._id === quotationProduct.productData._id);
    
                // If product details are found, filter out resources where resourceType is "video"
                const filteredProductDetails = productDetails
                    ? {
                        ...productDetails,
                        resources: productDetails.resources?.filter(resource => resource.resourceType !== "video") || []
                    }
                    : {};
    
                return {
                    ...quotationProduct,
                    productDetails: filteredProductDetails // Include filtered product details
                };
            });
    
            // Store the fetched details in the state
            setQuotationProducts(productsWithDetails);
        };
    
        fetchProductDetails();
    }, [quotationData, productCollection]);    

    return (
        <Card className="bg-white shadow-sm mb-4" style={{ width: '100%' }}>
            <Card.Body>
                <Row>
                    <Col md="12">
                        <div className="preview-container">
                            <div className="preview-header">
                                <Image src={SeaRoadBrand} className="navbar-brand-light" alt="SEA ROAD TRADING INC." />
                                <div className="company-info">
                                    <h2>SEA ROAD TRADING INC.</h2>
                                    <p>Gochan Compound, Warehouse 5B, MJ Cuenco Avenue, Mabolo, Cebu City, Philippines</p>
                                    <p>Telephone No.: (032) 434-6453 | TIN#: 274-454-701-000</p>
                                </div>
                            </div>
                            <div className="border-line-up"></div>
                            <div className="border-line-down"></div>
                        </div>
                    </Col>
                </Row>
                <div className="preview-body clearfix mb-5">
                    <h1>Quotation</h1>
                    <div className="left">
                        <p>To: {`${quotationData.clientData.firstName} ${quotationData.clientData.lastName}`}</p>
                        {quotationData.quotationProductNotes ? (
                            <p>Re: Product Quotation / {quotationData.quotationProductNotes}</p>
                        ) : (
                            <p>Re: Product Quotation</p>
                        )}
                        
                        <p>Valid Until: {formattedValidUntilDate}</p>
                    </div>
                    <div className="right">
                        <p>Quotation No: {quotationData.quotationNumber}</p>
                        <p>Date: {formattedQuotationDate}</p>
                    </div>
                </div>
                <p className="mb-2">Hi {quotationData.clientData.firstName},</p>
                <p>Good day! We truly appreciate your trust in choosing our services. Regarding your product requirements, we are pleased to present our quotation for your consideration.</p>
                <div className="preview-responsive">
                    <table className="tbl-document-preview">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Product</th>
                                <th>Model</th>
                                <th>Description</th>
                                <th>Quantity <span>(pcs)</span></th>
                                <th>Unit Price Landed Cebu <span>(PHP/pc)</span></th>
                                <th>Total Amount<span>(PHP)</span></th>
                                <th>Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                        {quotationProducts.map((product, index) => (
                            <tr key={product._id}>
                                <td>{index + 1}</td>
                                <td>{product.productDetails?.name || "Unknown Product"}</td>
                                <td>{product.model}</td>
                                <td className="description-col">
                                    <Row>
                                        <Col md="12" className="item-description mb-2">
                                            <p>{product.productDetails?.description || "No description available."}</p>
                                        </Col>
                                    </Row>
                                    {quotationProducts.length > 0 && (
                                        <Row>
                                            {product.productDetails?.resources?.map((resource, i) => (
                                                <Col md="3" key={resource._id}>
                                                    <Image
                                                        className="main-view"
                                                        fluid
                                                        rounded
                                                        src={resource.resourceUrl}
                                                        alt={`Product Resource ${i + 1}`}
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                    )}
                                </td>
                                <td>{product.quantity}</td>
                                <td>{formatPrice(product.productBasePrice)}</td>
                                <td>{formatPrice(product.totalBasePrice)}</td>
                                <th>{product.remarks || ""}</th>
                            </tr>
                        ))}
                    </tbody>

                    </table>
                </div>
                <table className="table table-remarks">
                    <tbody>
                        <tr>
                            <td><h3>Terms and Condition</h3></td>
                        </tr>
                        <tr>
                            <td>1. Payment Terms: {quotationData.paymentTermData.description}</td>
                        </tr>
                        <tr>
                            <td>2. Shipment: {getShipmentTypeDescription(quotationData.shipmentType)}</td>
                        </tr>
                        <tr>
                            <td>3. Delivery Terms: {quotationData.deliveryTerm}</td>
                        </tr>
                        <tr>
                            <td>4. Notes: {quotationData.shipmentNotes}</td>
                        </tr>
                        <tr>
                            <td>For oversize and overweight cargo which cannot be handled by our labor, client should prepare equipment to unload; if shipment need extra equipment (ex. boom truck or crane), client will shoulder the cost.</td>
                        </tr>
                        <tr>
                            <td>5. Delivery Period: <b>{quotationData.shipmendeliveryPeriodtNotes}</b> days after receiving downpayment.</td>
                        </tr>
                        <tr>
                            <td>6. Package: {quotationData.packageDescription}</td>
                        </tr>
                        <tr>
                            <td>7. Others: {quotationData.otherNotesForTermsAndCondition}</td>
                        </tr>
                        <tr className="quotation-remarks-space"><td></td></tr>
                        <tr className="account">
                            <td><span>We would like to thank you for giving us the opportunity to serve you.</span></td>
                        </tr>
                        <tr className="account">
                            <td><span>We hope to hear your confirmation soon.</span></td>
                        </tr>
                        <tr className="quotation-remarks-space"><td></td></tr>
                        <tr className="account">
                            <td><span>Very Truly Yours,</span></td>
                        </tr>
                        <tr className="account">
                            <td><span>{`${quotationData?.agentData?.firstname} ${quotationData?.agentData?.lastname}`}</span></td>
                        </tr>
                    </tbody>
                </table>
            </Card.Body>
        </Card>
    );
};

export default SalesQuotationPreview;
