import { requests } from './api-service';

const userApi = {
  getProfileInfo: (userId) => requests.get(`user/profile/${userId}`),
  create: (user) => requests.post('user', user),
  update: (user) => requests.put('user', user),
  uploadPhoto: (formData) => requests.putForm('user/photo/upload', formData),
  getCount: (search) => requests.get('users/count', setUserCountParams(search)),
  list: (pageNumber, search) => requests.get('users', setUserListParams(pageNumber,search)),
  setUserStatus: (data) => requests.put('users/setstatus', data),
  getUserCompanyAffiliate: (userId) => requests.get(`user/companyaffiliate/${userId}`),
  sendAccountActivation: (data) => requests.put('user/invite-to-activate', data),
  userResetPassword: (formData) => requests.putForm('user/account-reset-password', formData),
  userConfirmResetPassword: (formData) => requests.putForm('/user/confirm-reset-password', formData),
  setUserAsSalesAgent: (data) => requests.post('user/set/sales-agent', data),
  updateSalesAgentStatus: (data) => requests.post('user/toggle-sales-agent-status', data),
};

export const setUserCountParams = (search) => {
  const countParams = new URLSearchParams();
  if (search) countParams.append('search', search);
  return countParams;
};

export const setUserListParams = (pageNumber = 1,search = '') => {
  const limit = process.env.REACT_APP_PAGINATION_LIMIT || '10';
  const params = new URLSearchParams();
  params.append('pageNumber', pageNumber.toString());
  params.append('pageSize', limit.toString());
  if (search) params.append('search', search);
  return params;
};

export default userApi;
