import React from "react";
import { Card, Row, Col } from "@themesberg/react-bootstrap";
import '../billingprocessor/styles.scss';
import { formatCurrency } from "../utils/formatter";
import { CANCELLED } from "../utils/constants";
import BillingItems from "./billingitems";

const BillingDetail = (props) => {
    const { billingDocumentData } = props;

    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className="d-none d-sm-block total-billable-amount mb-4">
                        <h5>Total Billable Amount:</h5>
                        <h2 className="fs-1 fw-extrabold mb-1">{formatCurrency(parseFloat(billingDocumentData.totalBillableAmount)) || 0.00}</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <Card border="light" className="shadow-sm">
                        <Card.Body>
                            <h5 className="mb-3">Client Information</h5>
                            <p>Reference: <b>{billingDocumentData.generatedBillingData.clientRef}</b></p>
                            <p>Tracking Number: <b>{billingDocumentData.generatedBillingData.trackingReference}</b></p>
                            <p>First Name: <b>{billingDocumentData.clientData.firstName}</b></p>
                            <p>Last Name: <b>{billingDocumentData.clientData.lastName}</b></p>
                            <p>Email: <b>{billingDocumentData.clientData.email}</b></p>
                            <p>Contact: <b>{billingDocumentData.clientData.mobileNumber || billingDocumentData.clientData.phoneNumber}</b></p>
                            <p>Display Name: <b>{billingDocumentData.clientData.displayName}</b></p>
                            <p>&nbsp;</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card border="light" className="shadow-sm">
                        <Card.Body>
                            <h5 className="mb-3">Sum & Applied Rate</h5>
                            <p>Sum of CBM: <b>{parseFloat(billingDocumentData.sumOfCBM).toFixed(2)}</b></p>
                            <p>Sum of Sensitive: <b>{parseFloat(billingDocumentData.generatedBillingData.sumOfSensitiveCharge).toFixed(2)}</b></p>
                            <p>Sum of Forklift: <b>{parseFloat(billingDocumentData.sumOfForklift).toFixed(2)}</b></p>
                            <p>Sum of Inland: <b>{parseFloat(billingDocumentData.sumOfInland).toFixed(2)}</b></p>
                            <p>Sum of Gross Wt: <b>{parseFloat(billingDocumentData.sumOfGrossWeight).toFixed(2)}</b></p>
                            <p>Applied Rate: <b>{formatCurrency(parseFloat(billingDocumentData.appliedRate))}</b></p>
                            <p>Applied Unit: <b>{billingDocumentData.appliedUnit}</b></p>
                            <p>&nbsp;</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card border="light" className="shadow-sm">
                        <Card.Body>
                            <h5 className="mb-3">Total Records</h5>
                            <p>Freight Amount: <b>{formatCurrency(parseFloat(billingDocumentData.grossAmount))}</b></p>
                            <p>Total of Sensitive: <b>{formatCurrency(parseFloat(billingDocumentData.totalSensitiveCharge))}</b></p>
                            <p>Total of Forklift: <b>{formatCurrency(parseFloat(billingDocumentData.totalForkliftCharge))}</b></p>
                            <p>Total of Inland: <b>{formatCurrency(parseFloat(billingDocumentData.totalInlandCharge))}</b></p>
                            <p>Total Custom Clearance: <b>{formatCurrency(parseFloat(billingDocumentData.totalOfCustomsClearance))}</b></p>
                            <p>Total of OD4D: <b>{formatCurrency(parseFloat(billingDocumentData.totalOfoc4d))}</b></p>
                            <p>Exceed Max Wt?: <b>{billingDocumentData.exceededMaxWeight ? 'Yes' : 'No'}</b></p>
                            <p>&nbsp;</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card border="light" className="shadow-sm">
                        <Card.Body>
                            <h5 className="mb-3">More Information</h5>
                            <p>Container No.: <b>{billingDocumentData.generatedBillingData.containerNumber}</b></p>
                            <p>Invoice No.: <b>{billingDocumentData.generatedBillingData.invoiceNumber}</b></p>
                            <p>Billing Date: <b>{billingDocumentData.generatedBillingData.billingDateAsString}</b></p>
                            <p>USD conversion: <b>{formatCurrency(parseFloat(billingDocumentData.containerUSDConversion))}</b></p>
                            <p>RMB conversion: <b>{formatCurrency(parseFloat(billingDocumentData.containerRMBConversion))}</b></p>
                            <p>Status: <b>{billingDocumentData.generatedBillingData.status}</b></p>
                            <p>Quotient: <b>{billingDocumentData.generatedBillingData.quotient.toFixed(2)}</b></p>
                            {billingDocumentData.generatedBillingData.status === CANCELLED ? (
                                <p>Cancel Reason: <b>{billingDocumentData.generatedBillingData.cancelReason}</b></p>
                            ) : (
                                <p>&nbsp;</p>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <BillingItems billingItems={billingDocumentData.billingItems} />
                </Col>
            </Row>
        </>
    );
};

export default BillingDetail;
