import React from 'react';
import MainRouter from './MainRouter';
import ScrollToTop from './components/ScrollToTop';

if (process.env.NODE_ENV === 'production') {
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
    for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value === 'function' ? () => {} : null;
    }
  }
}

const App = () => (
  <>
    <ScrollToTop />
    <MainRouter />
  </>
);

export default App;
