import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPortalPage } from '../actions/blanket';

const SalesAgentTransactions = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPortalPage('Sales Agent Transaction - Reports'));
    }, [dispatch]);

    return <></>
}

export default SalesAgentTransactions;