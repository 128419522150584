import React from "react";
import { Card, Form, Button } from '@themesberg/react-bootstrap';
import { faCheckCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from "react-bootstrap";
import { confirmArchiveDialog } from "../components/Alerts";
import { hasPermission } from "../auth/UserPermissionTypes";
import { CLIENT_MANAGER_ADD_SPECIAL_RATES, CLIENT_MANAGER_DELETE_SPECIAL_RATES, CLIENT_MANAGER_UPDATE_SPECIAL_RATES } from "../utils/constants";

const ClientSpecialRates = (props) => {
    const { editSpecialRate, addSpecialRate, clientSpecialRates, specialRateStatusUpdate, isEditMode } = props;

    const handleCreateRateDetail = (rateData) => {
        const {fixRate, bracketRate, startQuantity, endQuantity, rateCategory } = rateData;

        if(fixRate > 0){
            return `${fixRate} per ${rateCategory.categoryUnit}`;
        }
        
        return `${bracketRate} of (${startQuantity} ${rateCategory.categoryUnit} - ${endQuantity} ${rateCategory.categoryUnit})`;
    }

    const handleSetSpecialRateStatus = async (id, status) => {
        let eventMessage = "Deactivate";
        if(status) eventMessage = "Re-activate"
        
        let text = `Do you want to ${eventMessage} this special rate?`;
        let confirmBtnText = `Yes, ${eventMessage} it!`;
        let callbackTitle = !status ? 'Deactivated!': 'Activated!';
        let callbackText = `Client special rate has been ${!status ? 'Deactivated!': 'Activated!'}`;
    
        var statusConfirm = await confirmArchiveDialog(text, confirmBtnText, callbackTitle, callbackText);
        if (statusConfirm) {
          specialRateStatusUpdate(id, status);
        }
    };

    return (
        <Card border="light" className="shadow-sm mt-4">
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between border-bottom border-light pb-3">
                <Row>
                    <Col md={10} className="mb-2">
                        <h5>Client Special Rates</h5>
                        <label className="mb-0 wrap-label"><b>Note:</b> You cannot edit special rate when it is used in transactions</label>
                    </Col>
                    {isEditMode && hasPermission(CLIENT_MANAGER_ADD_SPECIAL_RATES) && (
                        <Col md={2} className="mb-2">
                            <Button variant="primary" size="sm" onClick={() => addSpecialRate()}>Add</Button>
                        </Col>
                    )}
                </Row>
            </div>
            <div className="d-block">
                {clientSpecialRates.map((item, index) => (
                    <div key={index} className="d-flex align-items-center justify-content-between border-bottom border-light py-3">
                        <div>
                            <h6 className="mb-0">
                                {isEditMode && hasPermission(CLIENT_MANAGER_UPDATE_SPECIAL_RATES) && (
                                    <FontAwesomeIcon 
                                        icon={faPencilAlt}
                                        className="icon icon-xs me-2 text-purple clickable-icon"
                                        onClick={() => editSpecialRate(item)}
                                    />
                                )}
                                {handleCreateRateDetail(item)}
                            </h6>
                        </div>
                        {hasPermission(CLIENT_MANAGER_DELETE_SPECIAL_RATES) && (
                            <div>
                                {isEditMode ? (
                                    <Form.Group id="notify-email-check">
                                        <Form.Check
                                            defaultChecked={item.isActive}
                                            type="switch"
                                            name="client-special-rates"
                                            id="client-special-rates-01"
                                            htmlFor="client-special-rates-01"
                                            onChange={() => handleSetSpecialRateStatus(item._id, !item.isActive)}
                                        />
                                    </Form.Group>
                                ) : (
                                    <>
                                        {item.isActive && (
                                            <h6 className="fw-normal text-gray">
                                                <FontAwesomeIcon icon={faCheckCircle} className="icon icon-xs text-success w-20 me-1 mt-2" />
                                            </h6>
                                        )}
                                    </>
                                )}
                                
                            </div>
                        )}
                    </div>
                ))}
            </div>
          </Card.Body>
        </Card>
    );
}

export default ClientSpecialRates;